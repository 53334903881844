<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template #activator="{ on }">
			<v-btn text small color="primary" v-on="on">
				{{ $t('cv.addNewFem') }}
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('documents.addNewTemplate') }}</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-text-field v-model="name" :label="$t('auth.name')" :counter="nameMaxLength" :rules="rules.name" />
					<v-autocomplete
						v-model="records"
						:label="$t('documents.records')"
						:placeholder="$t('studies.select')"
						:items="recordsDocuments"
						item-text="name"
						item-value="id"
						return-object
						outlined
					/>
					<v-autocomplete
						v-model="cv"
						:label="$t('documents.cv')"
						:placeholder="$t('studies.select')"
						:items="cvDocuments"
						item-text="name"
						item-value="id"
						return-object
						outlined
					/>
					<v-autocomplete
						v-model="motivation"
						:label="$t('documents.motivationLetter')"
						:placeholder="$t('studies.select')"
						:items="motivationDocuments"
						item-text="name"
						item-value="id"
						return-object
						outlined
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="secondary" text @click="switchEditMode()">
					{{ $t('search.close') }}
				</v-btn>
				<v-btn color="blue darken-1" text @click="callCreateTemplate()">
					{{ $t('settings.add') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SettingsDocumentsTemplatesNew',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			editMode: false,
			valid: false,
			name: '',
			records: null,
			cv: null,
			motivation: null,
			rules: {
				name: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.nameMaxLength) || this.$t('rules.length', { length: this.nameMaxLength }),
				],
			},
		}
	},
	computed: {
		nameMaxLength() {
			return 64
		},
		recordsDocuments() {
			return this.documents ? this.documents.filter((e) => e.type.key == 'records') : []
		},
		cvDocuments() {
			return this.documents ? this.documents.filter((e) => e.type.key == 'cv') : []
		},
		motivationDocuments() {
			return this.documents ? this.documents.filter((e) => e.type.key == 'motivation') : []
		},
		...mapGetters({
			documents: 'documents/documents',
		}),
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callCreateTemplate() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.createDocumentsTemplate({
				name: this.name,
				recordsID: this.records ? this.records.id : 0,
				cvID: this.cv ? this.cv.id : 0,
				motivationID: this.motivation ? this.motivation.id : 0,
			})
				.then(({ success }) => {
					if (success) this.switchEditMode()
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('documents', ['createDocumentsTemplate']),
	},
}
</script>
